@import "../../../variables";

footer {
	height: 62px;

	.play-for {
		position: absolute;
		right: 0;
		bottom: 0px;

		svg {
			position: relative;
			width: 83px;
			margin-bottom: -13px;
		}

		.banner-content {
			position: absolute;
			transform: translateX(-50%);
			left: 50%;
			bottom: 5px;
			font-size: 10px;
			line-height: 5px;
			text-align: center;
			text-transform: uppercase;
			width: 75px;

			span {
				font-size: 10px;
				line-height: 10px;
			}

			strong {
				display: block;
				font-size: 26px;
				line-height: 32px;
				font-weight: 700;
			}
		}
	}
}
