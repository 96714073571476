@import '../../../../../variables';

.knock-pregame {
	$card-border-radius: 15px;
	$dark-color: #1e2021;

	$animation-duration: .2s;

	@mixin border-radius($radius) {
		border-radius: $radius;

		@media (max-width: 767px) {
			border-radius: $radius / 3;
		}
	}

	.title {
		margin-bottom: 20px;
	}

	.game-wrapper {
		padding-left: 12.5px;
		padding-right: 12.5px;
	}

	.cards-list {
		max-width: 320px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		li {
			width: 33.33%;
			padding: 7.5px;
		}

		.card-container, .card, .card .side {
			width: 100%;
			height: 100%;
			display: block;
		}
		.card-container {
			cursor: pointer;
			perspective: 600px;
		}
		.card {
			@include border-radius($card-border-radius);
			position: relative;
			transition: transform .8s, box-shadow $animation-duration, border-color $animation-duration;
			transform-style: preserve-3d;
			box-shadow: 0 0 3px rgba(black, .15);
			//border: 2px solid #bababa;
			padding-bottom: 165%;

			.side {
				-webkit-backface-visibility: hidden;
				backface-visibility: hidden;
				@include border-radius($card-border-radius - 2);
			}
			.front {
				background-size: cover;
				background-position: center;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
			}
			.back {
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
				transform: rotateY(180deg);
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;

				.prize {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					text-align: center;

					p {
						margin-bottom: 5px;
						font-size: 42px;
						line-height: 1;
						color: $color-white;
					}

					span {
						font-size: 14px;
						line-height: 1;
						color: $color-white;
					}
				}
			}
		}
		.card-container:hover .card {
			//border: 2px solid $color-main;
			box-shadow: 0 0 10px rgba($color-main, .6);

			.back::after {
				opacity: .5;
			}
		}
		.card-container:hover .card.flipped {
			//border: 2px solid darken($color-main, 5%);
		}

		.card.flipped {
			//border: 2px solid darken($color-main, 10%);
			transform: rotateY(180deg);
		}
	}
}
