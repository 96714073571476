@import '../../../../variables';

.forgot-pass-component {
	min-height: 201px;
}

@media (max-height: 574px) {
	.forgot-pass-component {
		min-height: 157px;
	}
}
