@import '../../../variables';

.modal-language {
	height: 100%;
	width: 100%;
	background-color: $color-bg;

	.close-modal {
		position: absolute;
		top: 17px;
		left: 10px;
		padding: 0;
		background-color: transparent;
		border: none;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 25px;
	}

	.languages-container {
		width: 80%;
		margin: 33% auto 0;
	}

	h2 {
		margin-bottom: 3px;
		font-size: 22px;
		border-bottom: 2px solid $color-text;
		color: $color-text;
		padding-bottom: 10px;
		padding-left: 30px;
		line-height: 1.2;
	}

	.language-content {
		padding: 10px 30px;
	}

	.custom-radio {
		input[type="radio"] {
			&:checked,
			&:not(:checked) {
				position: absolute;
				left: -9999px;

				& + label {
					font-size: 22px;
					line-height: 22px;
					color: $color-text;
					padding-top: 5px;
					padding-bottom: 5px;
					margin-bottom: 15px;
					cursor: pointer;
					display: block;
					position: relative;

					&::before {
						content: '';
						position: absolute;
						right: 0;
						top: 0;
						bottom: 0;
						margin: auto 0;
						width: 24px;
						height: 24px;
						background: transparent;
						border-radius: 100%;
						border: 4px solid $color-text
					}

					&::after {
						content: '';
						width: 16px;
						height: 16px;
						background: $color-text;
						position: absolute;
						right: 8px;
						top: 8px;
						border-radius: 100%;
						-webkit-transition: all 0.2s ease;
						transition: all 0.2s ease;
					}
				}
			}

			&:not(:checked) + label::after {
				opacity: 0;
				-webkit-transform: scale(0);
				transform: scale(0);
			}

			&:checked + label::after {
				opacity: 1;
				-webkit-transform: scale(1);
				transform: scale(1);
			}
		}
	}
}
