@import '../../../../../variables';

.quiz {
	.title {
		margin-bottom: 15px;

		p {
			margin-bottom: 0;
		}
	}

	.quiz-area {
		padding-left: 20px;
		padding-right: 20px;
	}

	.question {
		min-height: 60px;
		margin-bottom: 15px;
		text-align: center;
		font-size: 18px;
	}

	.answers-list {
		min-height: 206px;
	}

	.answer {
		position: relative;
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}

		&.selected {

			&.correct {

				button {
					background-color: $color-correct;
					color: $color-white;
				}
			}

			&.incorrect {

				button {
					background-color: $color-incorrect;
					color: $color-white;
				}
			}
		}

		button {
			width: 100%;
			height: 44px;
			border: 0;
			background-color: $color-white;
			font-size: 18px;
			color: $color-text;

			&:focus {
				outline: 0;
			}
		}
	}

	.total-questions {
		margin-top: 15px;
		text-align: center;
	}

	.answer-transition {
		position: relative;
		opacity: 0;
		left: -1000px;
	}

	.answer-transition-enter {
		position: relative;
		opacity: 0;
		left: -500px;
	}
	.answer-transition-enter-active {
		opacity: 1;
		transition: all 200ms ease-in;
		left: 0;
	}
	.answer-transition-exit {
		opacity: 1;
		left: 0;
	}
	.answer-transition-exit-active {
		opacity: 0;
		left: -500px;
		transition: all 200ms ease-in;
	}

	.question-transition {
		opacity: 0;
	}

	.question-transition-enter {
		opacity: 0;
	}
	.question-transition-enter-active {
		opacity: 1;
		transition: all 200ms ease-in;
	}
	.question-transition-exit {
		opacity: 1;
	}
	.question-transition-exit-active {
		opacity: 0;
		transition: all 200ms ease-in;
	}
}

@media (max-height: 550px) {
	.quiz {
		.title {
			margin-bottom: 7px;

			p {
				margin-top: 5px;
			}
		}

		.question {
			font-size: 16px;
			min-height: 54px;
			margin-bottom: 7px;
		}

		.answers-list {
			min-height: 170px;
		}

		.answer {
			margin-bottom: 6px;

			button {
				height: 38px;
				font-size: 16px;
			}
		}

		.total-questions {
			margin-top: 7px;
		}
	}
}
