@import "../../variables.scss";

.avatar{
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-white;

    img{
				padding: 5px;
        max-width: 100%;
    }
}
