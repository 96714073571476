@font-face {
	font-family: "Ooredoo";
	src: url("resources/fonts/Ooredoo-Heavy.woff2") format("woff2"),
		url("resources/fonts/Ooredoo-Heavy.woff") format("woff");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "ProductSans";
	src: url("resources/fonts/ProductSans-Bold.ttf");
}
