@import "../../../variables";

.result-component {
	padding-left: 20px;
	padding-right: 20px;

	.total-points {
		text-align: center;

		.gained-points {
			display: block;
			margin-bottom: -2px;
			opacity: .6;
			font-size: 15px;
			color: $color-main;
		}

		.points {
			font-size: 24px;
			color: $color-main;
		}

		p {
			font-size: 9px;
			line-height: 14px;
		}
	}

	.btn-again {
		display: block;
		margin-top: 40px;

		&.inactive {
			//.btn {
			//	background-color: $color-white;
			//	color: $color-bg;
			//}
		}

		.countdown {
			display: inline-block;
			width: 85px;
			margin-left: 5px;
		}
	}

	.btn-next {
		display: block;
		margin-top: 20px;
	}
}
