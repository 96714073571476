@import '../../../../../variables';

.drag-game {

	.game-wrapper {
		padding-left: 10px;
		padding-right: 10px;

		.original {
			width: 100%;
			margin-bottom: 10px;
			background-color: $color-bg-question;
			text-align: center;

			img {
				width: 120px;
				margin-left: auto;
				margin-right: auto;
			}
		}

		.answers-list {
			display: flex;
			justify-content: space-between;

			.answer {
				padding-left: 10px;
				padding-right: 10px;

				img {
					max-width: 100%;
				}
			}
		}
	}
}
