@import '../../../../../variables';

.puzzle-game {

	.piece-stroke {
		position: absolute;
		top: 0;
		left: 0;
	}

	.wrapper-canvas {
		//height: 285px;
		//width: 285px;
		display: flex;
		margin: 0 auto;
		position: relative;
		width: 220px;
		height: 223px;
		flex-wrap: wrap;

		li {
			position: relative;
			//position: absolute;
			//height: 76px;
			//width: 95px;
			overflow: hidden;

			canvas {
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		.puzzle-piece {

			.image {
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				right: 0;
			}
		}

		.puzzle-piece-1 {

		}

		.puzzle-piece-2 {
			margin-left: -23px;
		}

		.puzzle-piece-3 {
			margin-left: -23px;
		}

		.puzzle-piece-4 {
			margin-top: -23px;
		}

		.puzzle-piece-5 {
			margin-top: -24px;
			margin-left: -23px;
		}

		.puzzle-piece-6 {
			margin-top: -24px;
			margin-left: -24px;
		}

		.puzzle-piece-7 {
			margin-top: -43px;
		}

		.puzzle-piece-8 {
			margin-top: -24px;
			margin-left: -25px;
		}

		.puzzle-piece-9 {
			margin-top: -42px;
			margin-left: -25px;
		}

		//.puzzle-piece-1 {
		//	left: 11.8%;
		//
		//	svg {
		//		width: 98%;
		//	}
		//}
		//
		//.puzzle-piece-2 {
		//	left: 3.2%;
		//
		//	svg {
		//		width: 100%;
		//	}
		//}
		//
		//.puzzle-piece-3 {
		//	left: -4.7%;
		//
		//	svg {
		//		width: 78.5%;
		//	}
		//}
		//
		//.puzzle-piece-4 {
		//
		//	svg {
		//		width: 100%;
		//	}
		//}
		//
		//.puzzle-piece-5 {
		//
		//	svg {
		//		width: 83%;
		//	}
		//}
		//
		//.puzzle-piece-6 {
		//
		//	svg {
		//		width: 100%;
		//	}
		//}

		//.puzzle-piece-1 {
		//	top: 0;
		//	left: 34.5px;
		//}
		//
		//.puzzle-piece-2 {
		//	top: 0;
		//	left: 106.5px;
		//}
		//
		//.puzzle-piece-3 {
		//	top: 0;
		//	left: 180.5px;
		//}
		//
		//.puzzle-piece-4 {
		//	top: 95px;
		//	left: 0px;
		//}
		//
		//.puzzle-piece-5 {
		//	top: 95px;
		//	left: 95px;
		//}
		//
		//.puzzle-piece-6 {
		//	top: 95px;
		//	left: 190px;
		//}
		//
		//.puzzle-piece-7 {
		//	top: 190px;
		//	left: 0px;
		//}
		//
		//.puzzle-piece-8 {
		//	top: 190px;
		//	left: 95px;
		//}
		//
		//.puzzle-piece-9 {
		//	top: 190px;
		//	left: 190px;
		//}
	}

	.answers-list {
		display: flex;
		margin-top: 20px;
		padding-left: 10px;
		padding-right: 10px;
		justify-content: space-between;
		align-items: center;

		li {
			display: flex;
			align-items: center;

			.image-piece {
				clip-path: url(#myClip);
			}
		}
	}
}
