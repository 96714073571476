@import '../../../../../variables';

.spin-pregame {
	--wheel-size: 280px;
	--wheel-slice-spacing: 0px;
	--wheel-border-size: 0px;
	--wheel-color: white;
	--neutral-color: white;
	--PI: 3.14159265358979;
	--nb-item: 0;
	--item-nb: 0;
	--selected-item: 0;
	--nb-turn: 21;
	--spinning-duration: 4s;
	--reset-duration: 0.25s;
	-webkit-tap-highlight-color:transparent;

	.title {
		margin-bottom: 20px;
	}
}

.wheel-container {
	transform: rotate(-90deg);
	display: block;
	position: relative;
	box-sizing: content-box;
	width: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
	height: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
	padding: 3px;
	margin: auto;
	background-color: var(--neutral-color);
	border: solid var(--wheel-color) 3px;
	border-radius: 50%;
	user-select: none;

	.spin-text {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1;
		transform: rotate(90deg);
		text-align: center;
		pointer-events: none;

		&.hide {
			display: none;
		}

		p {
			width: 50px;
			font-size: 13px;
			line-height: 1;
			color: #d72a33;
		}
	}
}

/* Triangle de sélection = 1 rose +  1 blanc */
.wheel-container::before,
.wheel-container::after {
	content: '';
	display: block;
	position: absolute;
	height: 0;
	width: 0;
	top: 50%;
	transform: translateY(-50%);
	z-index: 2;
	border: solid transparent 20px;
	border-left-width: 0;
}

.wheel-container::before {
	right: 0px;
	border-right-color: var(--wheel-color);
}

.wheel-container::after {
	right: -5px;
	border-right-color: var(--neutral-color);
}

/* Roue */
.wheel {
	display: block;
	position: relative;
	box-sizing: content-box;
	margin: auto;
	width: var(--wheel-size);
	height: var(--wheel-size);
	overflow: hidden;
	border-radius: 50%;
	border: solid var(--wheel-color) var(--wheel-border-size);
	background-color: var(--wheel-color);
	transition: transform var(--reset-duration);
	transform: rotate(0deg);
	cursor: pointer;
}

.wheel.spinning {
	transition: transform var(--spinning-duration);
	transform: rotate(calc(var(--nb-turn) * 360deg + (-360deg * var(--selected-item) / var(--nb-item, 1))));
}

/* Centre de la roue = rond blanc au centre */
.wheel::after {
	display: block;
	position: absolute;
	content: '';
	background-color: var(--neutral-color);
	width: 50px;
	height: 50px;
	z-index: 2;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
}

/* Element sur la roue */
.wheel-item {
	display: block;
	position: absolute;
	box-sizing: border-box;

	/* position de l'item */
	top: 50%;
	left: 50%;
	width: 50%;
	transform-origin: center left;
	transform: translateY(-50%) rotate(calc(var(--item-nb) * (360deg / var(--nb-item, 1))));
}

.wheel-item p {
	height: calc(var(--wheel-size) / 2);
	transform: rotate(90deg);
	padding-top: 15px;
	color: var(--neutral-color);
	text-align: center;
	font-size: 14px;
	line-height: 14px;
	font-weight: 400;

	strong {
		display: block;
		font-size: 34px;
		line-height: 34px;
		font-weight: 400;
	}

	span {
		display: block;
	}
}

/* Background de l'élément = triangle rose plus clair */
.wheel-item:before {
	content: ' ';
	display: block;
	position: absolute;
	box-sizing: border-box;
	z-index: -1;
	width: 0;
	height: 0;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding-left: 0px;
	opacity: 1;
	--slice-max-width: 975px; /* calc(calc(var(--PI)*var(--wheel-size)) + calc(var(--wheel-size) / 2)) */
	--slice-width: calc((var(--slice-max-width) / var(--nb-item)) - var(--wheel-slice-spacing));
	border: solid transparent calc(var(--slice-width) / 2);
	border-left: solid transparent 0;
	/* profondeur du triangle = du centre de la roue à la bordure = 300px / 2 */
	border-right: solid var(--neutral-color) calc(var(--wheel-size) / 2);
}


.wheel-item:nth-child(1):before {
	border-right: solid rgb(97, 232, 225) calc(var(--wheel-size) / 2);
}

.wheel-item:nth-child(2):before {
	border-right: solid rgb(232, 144, 5) calc(var(--wheel-size) / 2);
}

.wheel-item:nth-child(3):before {
	border-right: solid rgb(99, 173, 242) calc(var(--wheel-size) / 2);
}

.wheel-item:nth-child(4):before {
	border-right: solid rgb(206, 109, 139) calc(var(--wheel-size) / 2);
}

.wheel-item:nth-child(5):before {
	border-right: solid rgb(236, 117, 5) calc(var(--wheel-size) / 2);
}

.wheel-item:nth-child(6):before {
	border-right: solid rgb(230, 50, 49) calc(var(--wheel-size) / 2);
}
