//Colors
$color-white: #ffffff;
$color-bg: #46b40b;
$color-main: #ed1c24;
$color-text: #ffffff;
$color-text-dark: #383838;
$color-error: #dc3545;
$color-valid: #28a745;
$color-correct: #0be038;
$color-incorrect: #c31919;
$color-bg-question: #e6e6e6;
$color-gray: #e7e7e7;
$color-gray-2: #8d8d8d;
$color-gray-3: #4a4d4e;
$color-purple: #a62aa2;
$color-button-text: #f60a0b;
//Fonts
$font-body: "ProductSans", sans-serif;
