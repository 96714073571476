@import '../../../../../../variables';

.scratch-pregame {
	.title {
		margin-bottom: 20px;
	}

	.cards-list {
		max-width: 342px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		position: relative;
		margin-left: auto;
		margin-right: auto;

		li {
			padding: 5px;

			.scratch-card-wrapper {
				position: relative;
				box-shadow: 0 3px 18px rgba(0,0,0,.25);
			}

			.prize {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				text-align: center;

				p {
					margin-bottom: 5px;
					font-size: 42px;
					line-height: 1;
					color: $color-white;
				}

				span {
					font-size: 14px;
					line-height: 1;
					color: $color-white;
				}
			}
		}
	}
}
