@import '../../../../variables';

.games-slider {

	.slick-list {
		box-sizing: initial;
		padding-top: 16px!important;
		padding-bottom: 16px!important;
	}

	.slick-slide {
		padding: 0 7.5px;
		box-sizing: border-box;
	}

	.slick-dots {
		bottom: -17px;
	}

	.item {
		//padding-right: 15px;

		a {
			display: block;
			position: relative;
			padding-bottom: 50%;
			background-color: $color-white;
			background-repeat: no-repeat;
			background-position: right;
			background-size: auto 100%;
			box-shadow: 0 3px 18px rgba(0,0,0,.25);

			.title-game {
				position: absolute;
				left: 6.46%;
				top: 10.33%;
				width: 44%;

				h5 {
					margin: 0;
					text-align: left;
					font-size: 24px;
					line-height: 26px;
					font-weight: 400;
					color: $color-purple;
				}

				h6 {
					margin-top: 15px;
					margin-bottom: 0;
					text-align: left;
					font-size: 14px;
					line-height: 14px;
					font-weight: 400;
					color: $color-text;
				}
			}

			p {
				position: absolute;
				left: 6.46%;
				bottom: 12.91%;
				margin: 0;
				text-align: center;
				font-size: 14px;
				text-transform: uppercase;
				line-height: 1;
				font-weight: 500;
				color: $color-gray-3;
				padding: 10px;
				padding-left: 0;
				letter-spacing: -0.3px;
				border-radius: 30px;

				&.completed {
					//color: $color-completed;

					&::before {
						background-image: url(./img/icon-completed.png);
						display: inline-block;
						content: '';
						width: 20px;
						height: 20px;
						background-size: 100%;
						top: 5px;
						position: relative;
						margin-right: 4px;
					}
				}
			}
		}
	}
}

@media (max-width: 345px) {
	.games-slider {
		.item {
			a {
				.title-game {
					h5 {
						font-size: 22px;
						line-height: 24px;
					}
				}

				p {
					font-size: 12px;
				}
			}
		}
	}
}
