@import '../../../../variables';

.player-stats-component {
	position: absolute;
	top: 7px;
	right: 0;
	height: 40px;
	text-align: right;
	display: flex;
	align-items: center;
	z-index: 1;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		background-image: url(./img/level-bg.png);
	}

	.stats-item {
		display: flex;
		align-items: center;
		z-index: 1;

		.stats-avatar {
			display: inline-block;
			height: 44px;
			width: 44px;
		}

		.stats-info {
			margin-right: 10px;
			margin-left: 5px;

			span {
						display: block;
						font-size: 18px;
						line-height: 21px;
						color: $color-gray-2;
						text-transform: uppercase;

						&.small {
							font-size: 8px;
							line-height: 10px;
						}
					}
		}
	}
}
