@import '../../../../variables';

.game-timer {
	width: 110px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 14px;
	padding-right: 14px;
	padding-top: 3px;
	padding-bottom: 3px;
	border: 3px solid $color-main;
	border-radius: 9px;
	background-color: $color-white;
	color: $color-main;

	.time-left {
		width: 46px;
	}
}


@media (max-height: 550px) {
	.game-timer {
		width: 100px;
		margin-bottom: 10px;
		font-size: 14px;

		img {
			height: 20px;
		}
	}
}
