@import '../../../variables';

nav {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	overflow: hidden;
	margin-left: -100%;
	transition: margin-left .2s ease-in;
	z-index: 200;

	&.active {
		margin-left: 0;

		.nav-inner {
			overflow-y: auto;

			ul {
				left: 0;
				opacity: 1;
			}
		}
	}

	.nav-inner {
		height: 100%;
		width: 100%;
		background-color: $color-bg;

		ul {
			position: relative;
			left: -50px;
			padding-top: 100px;
			text-align: center;
			opacity: 0;
			transition: all .2s ease-in;
			transition-delay: .2s;

			li {
				padding: 2% 0;

				&.restricted {
					a {
						display: inline-block;
						background-image: url('img/icon-locker.png');
						background-repeat: no-repeat;
						background-position: left center;
						background-size: 16px 20px;
						padding-left: 20px;
						opacity: .4;
					}
				}

				a {
					display: inline-block;
					font-size: 24px;
					line-height: 1.35;
					text-decoration: none;
					color: $color-text;
				}
			}
		}
	}
}


@media (max-height: 574px) {
	nav {
		.nav-inner {
			ul {
				padding-top: 58px;

				li {
					padding: 1.5% 0;
				}
			}
		}
	}
}
