@import '../../variables';

html:lang(ar) {

	.modal-general {
		text-align: right;

		.close-modal {
			right: auto;
			left: 10px;
		}
	}

	.modal-language {
		text-align: right;

		.close-modal {
			right: 10px;
			left: auto;
		}

		h2 {
			padding-right: 30px;
			padding-left: 0;
		}

		.custom-radio {
			input[type="radio"]:checked + label::before, input[type="radio"]:not(:checked) + label::before {
				right: auto;
				left: 0;
			}

			input[type="radio"]:checked + label::after, input[type="radio"]:not(:checked) + label::after {
				right: auto;
				left: 8px;
			}
		}
	}
}

.ar {
	text-align: right;

	.modal-general {
		text-align: right;

		.modal-title {
			text-align: right;
		}
	}

	.form-field {

		input:not([type="submit"]) {
			text-align: right;
		}

		.validation-message {
			right: 0;
			left: auto;
		}

		.prefix {
			left: auto;
			right: 29px;
		}
	}

	header {

		.nav-control {
			position: absolute;
			top: 0;
			right: 10px;

			.btn-nav {
				order: 2;

				img {
					transform: rotate(180deg);
				}
			}

			.logo-header {
				order: 1;
			}
		}

		.player-stats-component {
			left: 0;
			right: auto;

			&::before {
				transform: rotate(180deg);
			}

			.stats-item {

				.stats-avatar {
					order: 2;
				}

				.stats-info {
					order: 1;
					margin-right: 5px;
					margin-left: 10px;
				}
			}
		}
	}

	nav {
		position: fixed;
		margin-left: 0;
		right: -100%;
		transition: right .2s ease-in;
		left: auto;

		&.active {
			right: 0;

			.nav-inner {
				overflow-x: hidden;

				ul {
					left: auto;
					right: 0;
				}
			}
		}

		.nav-inner {

			ul {
				left: auto;
				right: -50px;
			}
		}
	}

	.auth-component {

		input:not([type="submit"]) {
			padding-left: 15px;
			padding-right: 55.5px;
			background-position: right 10px center;
		}
	}

	.about-component {
		.slick-slider {
			//direction: ltr;
		}
	}

	.games-slider .item a {
		background-position: left;

		.title-game {
			right: 6.46%;
			left: auto;

			h5 {
				text-align: right;
			}

			h6 {
				text-align: right;
			}
		}

		p {
			right: 6.46%;
			left: auto;
		}
	}

	.quiz {

		.answer-transition {
			position: relative;
			opacity: 0;
			right: -1000px;
			left: auto;
		}

		.answer-transition-enter {
			position: relative;
			opacity: 0;
			right: -500px;
			left: auto;
		}
		.answer-transition-enter-active {
			opacity: 1;
			transition: all 200ms ease-in;
			left: auto;
			right: 0;
		}
		.answer-transition-exit {
			opacity: 1;
			left: auto;
			right: 0;
		}
		.answer-transition-exit-active {
			opacity: 0;
			left: auto;
			right: -500px;
			transition: all 200ms ease-in;
		}
	}
}
