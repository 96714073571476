@import '../../../variables';

.confirm-component {
	padding-left: 20px;
	padding-right: 20px;

	.confirm-info {
		text-align: center;

		.info-content {
			max-width: 260px;
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			transform: translateY(-60%);
			margin: auto;
			text-transform: uppercase;

			span {
				display: block;
				font-size: 14px;
				line-height: 30px;
				color: $color-white;
			}

			strong {
				display: block;
				font-size: 110px;
				line-height: 112px;
				color: $color-white;
			}
		}
	}

	.btn {
		margin-top: -3px;
	}
}
