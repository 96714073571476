@import '../../../../../variables';

.memory-game {
	height: 100%;
	width: 100%;

	.title {
		margin-bottom: 15px;

		p {
			margin-bottom: 0;
		}
	}

	.cards-container {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		padding-left: 12.5px;
		padding-right: 12.5px;

		.card-container {
			width: 25%;
			padding: 7.5px;
		}
	}
}

@media (max-height: 550px) {
	.title {
		margin-bottom: 7px;

		p {
			margin-top: 5px;
		}
	}
}
