@import "../../variables";

.form-field {
	position: relative;

	&.rtl {

		//input:not([type="submit"]) {
		//	unicode-bidi:bidi-override;
		//	direction: RTL;
		//}

		input[type="password"] {
			unicode-bidi:bidi-override;
			direction: RTL;
		}
	}

	.prefix {
		position: absolute;
		left: 29px;
		top: 50%;
		transform: translateY(-50%);
		font-size: 14px;
		letter-spacing: -1px;
		color: $color-text-dark;
	}

	input:not([type="submit"]) {
		width: 100%;
		outline: 0;
		border: none;
		line-height: 45px;
		padding: 0 15px;
		font-size: 15px;
		color: $color-text-dark;
	}

	.validation-message {
		position: absolute;
		top: -10px;
		left: 0;
		font-size: 12px;
		color: $color-error;
		z-index: 1;
		padding-left: 10px;
		padding-right: 10px;
		background-color: $color-white;
	}
}

@media (max-height: 574px) {
	.form-field {
		input:not([type="submit"]) {
			line-height: 36px;
			font-size: 14px;
		}
	}
}
