@import '../../variables';

.about-component {

	.about-slider {
		text-align: center;

		.slick-dots {
			bottom: -37px;
		}

		.item {
			height: 288px;
			padding-top: 20px;
			position: relative;

			&:focus {
				outline: 0;
			}

			&.first-item {

				.featured-img {
					position: relative;
					margin-top: 15px;
					margin-left: auto;
					margin-right: auto;
					max-width: 210px
				}
			}

			&.second-item {

				.featured-img {
					text-align: center;

					span {
						display: block;
						color: $color-white;
					}

					span.points-big {
						font-size: 28px;
						opacity: .6;
					}

					span.points-small {
						font-size: 20px;
						opacity: .3;
					}

					img {
						margin-top: 15px;
						margin-left: auto;
						margin-right: auto;
						max-width: 120px;
						border-radius: 100%;
						box-shadow: 0 0 14px rgba(0, 0, 0, .5);
					}
				}
			}

			&.third-item {

				.featured-img {

					.skills-wrapper {
						position: relative;
						max-width: 260px;
						margin: 0 auto;
						margin-top: 69px;
						padding-bottom: 147px;

						span {
							position: absolute;
							font-size: 14px;
							color: $color-white;
						}

						span:nth-of-type(1) {
							top: 77%;
							left: 15%;
						}

						span:nth-of-type(2) {
							top: 86%;
							left: 0;
							right: 0;
						}

						span:nth-of-type(3) {
							top: 77%;
							left: 73%;
						}

						img {
							position: absolute;
							border-radius: 100%;
							box-shadow: 0 0 14px rgba(0, 0, 0, .5);
							margin: 0 auto;
						}

						img:nth-of-type(1) {
							width: 100px;
							top: 5px;
							left: 0;
						}

						img:nth-of-type(2) {
							width: 120px;
							left: 0;
							right: 0;
							z-index: 1;
						}

						img:nth-of-type(3) {
							top: 5px;
							width: 100px;
							right: 0;
						}
					}
				}
			}

			&.fourth-item {

				.featured-img {
					margin-top: 64px;
					margin-left: auto;
					margin-right: auto;
					max-width: 140px;
				}
			}

			.description {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
			}
		}
	}

	.btn-slider {
		display: block;
		margin-top: 68px;
		margin-left: 20px;
		margin-right: 20px;
	}

	.btn-warning {
		text-decoration: none;

		.btn {
			line-height: 1.3;
			height: 70px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
		}

		.warning-text {
			color: $color-text;
			display: block;
		}

		span {
			line-height: 1.3;
		}
	}
}

@media (max-height: 574px) {
	.about-component {
		.about-slider {
			.item {
				height: 267px;
				padding-top: 0;
			}

			.slick-dots {
				bottom: -15px;
			}
		}

		.btn-slider {
			margin-top: 20px;
		}
	}
}
