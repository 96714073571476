@import "../../variables";

.credits-component {
	padding-left: 20px;
	padding-right: 20px;

	h2 {
		margin-bottom: 20px;
		text-align: center;
	}

	.credits-types {
		max-width: 320px;
		margin: 0 auto;

		.credit-button {
			background-color: $color-main;
			color: $color-white;
			border: none;
			outline: none;
			margin-bottom: 8px;
			position: relative;
			height: 60px;
			display: flex;
			align-items: center;
			text-decoration: none;

			* {
				color: $color-white;
			}

			a {
				text-decoration: none;
			}
		}

		.description {
			padding-left: 30px;

			p {
				text-transform: uppercase;
				display: flex;
				align-items: center;

				img {
					margin-left: 4px;
					margin-right: 2px;
				}
			}

			.credits-no {
				font-size: 12px;
				line-height: 15px;
				padding-top: 2px;
			}
		}

		.price {
			position: absolute;
			top: -26%;
			right: -2px;
			font-size: 25px;
			font-weight: 400;
			display: flex;
			align-items: center;
			justify-content: center;

			span {
				font-size: 25px;
				color: $color-text;
				position: absolute;
				margin-top: 10px;
				-ms-transform: rotate(-11deg) translateY(-50%);
				-webkit-transform: rotate(-11deg) translateY(-50%);
				transform: rotate(-11deg) translateY(-50%);
				z-index: 1;
			}

			img {

			}
		}
	}
}
