@import '../../../../../variables';

.differences-game {

	.game-wrapper {
		padding-left: 20px;
		padding-right: 20px;

		.original {
			position: relative;
			margin-bottom: 20px;
			box-shadow: 0 3px 18px rgba(0,0,0,.25);

			img {
				max-width: 100%;
				display: block;
			}
		}

		.different {
			position: relative;
			box-shadow: 0 3px 18px rgba(0,0,0,.25);

			img {
				max-width: 100%;
				display: block;
			}
		}

		.difference {
			position: absolute;
			width: 6.8%;
			padding-bottom: 6.8%;

			&.selected {
				border: 1px solid red;
				border-radius: 100%;
				box-sizing: content-box;
			}
		}
	}
}
