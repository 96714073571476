@import "../../../../variables";

.play-component {

	.tip {
		margin-top: 30px;
		font-size: 14px;
		opacity: .8;
		text-align: center;
	}
}

@media (max-height: 574px)  {
	.play-component {
		.tip {
			margin-top: 10px;
		}
	}
}
